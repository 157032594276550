import React from 'react';
import PropTypes from 'prop-types';
import StandardModal from '@artemis/components/StandardModal';
import styled from 'styled-components';

const Title = styled.h2`
  font-weight: ${props => props.theme.typography.fontWeightBold};
  font-size: 1.25rem;
  text-align: center;
  padding-bottom: 10px;
`;
const Content = styled.div`
  text-align: center;
  padding-bottom: 0.5rem;
`;

const StandardPrompt = ({
  primaryButtonText,
  secondaryButtonText,
  title,
  content,
  onClose,
  isOpen,
  onClickPrimaryButton,
  onClickSecondaryButton,
  isLoading,
  isClosable,
  ...rest
}) => (
  <StandardModal
    onClose={onClose}
    isOpen={isOpen}
    primaryButtonText={primaryButtonText}
    onClickPrimaryButton={onClickPrimaryButton}
    secondaryButtonText={secondaryButtonText}
    onClickSecondaryButton={onClickSecondaryButton}
    mobileFullHeight={false}
    isLoading={isLoading}
    isClosable={isClosable}
    {...rest}
  >
    {title && <Title>{title}</Title>}
    {content && <Content>{content}</Content>}
  </StandardModal>
);

StandardPrompt.propTypes = {
  primaryButtonText: PropTypes.node,
  secondaryButtonText: PropTypes.node,
  title: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  onClickPrimaryButton: PropTypes.func,
  onClickSecondaryButton: PropTypes.func,
  isLoading: PropTypes.bool,
  isClosable: PropTypes.bool,
};

export default StandardPrompt;
