import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import StandardPrompt from '@artemis/components/StandardPrompt';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { getServiceFeeDisclosurePopup } from '@artemis/store/merchant/selectors';
import { setServiceFeeModalOpen } from '@artemis/store/menu/slice';
import { getIsServiceFeeModalOpen } from '@artemis/store/menu/selectors';

const Title = styled.div`
  text-align: left;
  ${props => props.theme.typography.h3};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-top: 8px;
`;

const Body = styled.div`
  ${props => props.theme.typography.body};
  text-align: left;
`;

const Subtitle = styled.div`
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-top: 8px;
`;

const Description = styled.div`
  color: ${props => props.theme.palette.grey[600]};
  margin-top: 8px;
`;

const ServiceFeeModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsServiceFeeModalOpen);
  const { subtitle, description } = useSelector(getServiceFeeDisclosurePopup);

  const onClose = () => dispatch(setServiceFeeModalOpen({ isOpen: false }));

  return (
    <StandardPrompt
      title={
        <Title>
          <FormattedMessage entry="menu.serviceFeeModal.title" />
        </Title>
      }
      content={
        <Body>
          {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
          <Description>{description}</Description>
        </Body>
      }
      primaryButtonText={<FormattedMessage entry="global.gotIt" />}
      onClose={onClose}
      isOpen={isOpen}
      onClickPrimaryButton={() => {
        onClose();
      }}
      isLoading={false}
      isClosable
      mobileFullHeight
      size="lg"
    />
  );
};

export default ServiceFeeModal;
